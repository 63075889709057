.pin-icon {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: 115px;

    border-radius: 50%;
    border: 8px solid rgb(1, 94, 10);
    width: 8px;
    height: 8px;
}

.pin-icon::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid #fff;
}

.map-container {
    width: 100%;
    height: 100vh;
}